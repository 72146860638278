/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The font file(s) should be placed relative to the regular CSS file.
 *
 */

/*
 * Legal Disclaimer for Web licence
 *
 * Licence to use self-hosted webfonts for displaying dynamic text on specified website domains. Our package includes WOFF and WOFF2 font formats.
 *
 * Should the license page views limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 *
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media.
 *
 * These Web fonts are licensed exclusively for the use on the following domain(s) and their subdomains:
 *
 * mediakunst.net (monthly pageviews: <50K)
 *
 */

/* complete */

@font-face {
  font-family: "ModerneLLWeb-BlackItalic";
  src: url("modules/asset/ModerneLLWeb-BlackItalic.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLWeb-BlackItalic";
  src: url("modules/asset/ModerneLLWeb-BlackItalic.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLWeb-BoldItalic";
  src: url("modules/asset/ModerneLLWeb-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLWeb-BoldItalic";
  src: url("modules/asset/ModerneLLWeb-BoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLWeb-Book";
  src: url("modules/asset/ModerneLLWeb-Book.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLWeb-Book";
  src: url("modules/asset/ModerneLLWeb-Book.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLWeb-Regular";
  src: url("modules/asset/ModerneLLWeb-Regular.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLWeb-Regular";
  src: url("modules/asset/ModerneLLWeb-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLWeb-BookItalic";
  src: url("modules/asset/ModerneLLWeb-BookItalic.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLWeb-BookItalic";
  src: url("modules/asset/ModerneLLWeb-BookItalic.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLWeb-Italic";
  src: url("modules/asset/ModerneLLWeb-Italic.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLWeb-Italic";
  src: url("modules/asset/ModerneLLWeb-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLWeb-LightItalic";
  src: url("modules/asset/ModerneLLWeb-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLWeb-LightItalic";
  src: url("modules/asset/ModerneLLWeb-LightItalic.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLWeb-Black";
  src: url("modules/asset/ModerneLLWeb-Black.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLWeb-Black";
  src: url("modules/asset/ModerneLLWeb-Black.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLWeb-Medium";
  src: url("modules/asset/ModerneLLWeb-Medium.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLWeb-Medium";
  src: url("modules/asset/ModerneLLWeb-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLWeb-FatItalic";
  src: url("modules/asset/ModerneLLWeb-FatItalic.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLWeb-FatItalic";
  src: url("modules/asset/ModerneLLWeb-FatItalic.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLWeb-Light";
  src: url("modules/asset/ModerneLLWeb-Light.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLWeb-Light";
  src: url("modules/asset/ModerneLLWeb-Light.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLWeb-MediumItalic";
  src: url("modules/asset/ModerneLLWeb-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLWeb-MediumItalic";
  src: url("modules/asset/ModerneLLWeb-MediumItalic.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLWeb-Fat";
  src: url("modules/asset/ModerneLLWeb-Fat.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLWeb-Fat";
  src: url("modules/asset/ModerneLLWeb-Fat.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLWeb-Bold";
  src: url("modules/asset/ModerneLLWeb-Bold.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLWeb-Bold";
  src: url("modules/asset/ModerneLLWeb-Bold.woff2") format("woff2");
}


/* subset */

@font-face {
  font-family: "ModerneLLSub-BlackItalic";
  src: url("modules/asset/ModerneLLSub-BlackItalic.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLSub-BlackItalic";
  src: url("modules/asset/ModerneLLSub-BlackItalic.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLSub-BoldItalic";
  src: url("modules/asset/ModerneLLSub-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLSub-BoldItalic";
  src: url("modules/asset/ModerneLLSub-BoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLSub-Book";
  src: url("modules/asset/ModerneLLSub-Book.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLSub-Book";
  src: url("modules/asset/ModerneLLSub-Book.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLSub-Regular";
  src: url("modules/asset/ModerneLLSub-Regular.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLSub-Regular";
  src: url("modules/asset/ModerneLLSub-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLSub-BookItalic";
  src: url("modules/asset/ModerneLLSub-BookItalic.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLSub-BookItalic";
  src: url("modules/asset/ModerneLLSub-BookItalic.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLSub-Italic";
  src: url("modules/asset/ModerneLLSub-Italic.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLSub-Italic";
  src: url("modules/asset/ModerneLLSub-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLSub-LightItalic";
  src: url("modules/asset/ModerneLLSub-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLSub-LightItalic";
  src: url("modules/asset/ModerneLLSub-LightItalic.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLSub-Black";
  src: url("modules/asset/ModerneLLSub-Black.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLSub-Black";
  src: url("modules/asset/ModerneLLSub-Black.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLSub-Medium";
  src: url("modules/asset/ModerneLLSub-Medium.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLSub-Medium";
  src: url("modules/asset/ModerneLLSub-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLSub-FatItalic";
  src: url("modules/asset/ModerneLLSub-FatItalic.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLSub-FatItalic";
  src: url("modules/asset/ModerneLLSub-FatItalic.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLSub-Light";
  src: url("modules/asset/ModerneLLSub-Light.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLSub-Light";
  src: url("modules/asset/ModerneLLSub-Light.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLSub-MediumItalic";
  src: url("modules/asset/ModerneLLSub-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLSub-MediumItalic";
  src: url("modules/asset/ModerneLLSub-MediumItalic.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLSub-Fat";
  src: url("modules/asset/ModerneLLSub-Fat.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLSub-Fat";
  src: url("modules/asset/ModerneLLSub-Fat.woff2") format("woff2");
}

@font-face {
  font-family: "ModerneLLSub-Bold";
  src: url("modules/asset/ModerneLLSub-Bold.woff") format("woff");
}

@font-face {
  font-family: "ModerneLLSub-Bold";
  src: url("modules/asset/ModerneLLSub-Bold.woff2") format("woff2");
}

/** These are the other fonts */
@font-face {
  font-family: "Reckless-Regular";
  src: url("modules/asset/Reckless-Regular.woff") format("woff");
}
@font-face {
  font-family: "Reckless-Regular";
  src: url("modules/asset/Reckless-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Reckless-RegularItalic";
  src: url("modules/asset/Reckless-RegularItalic.woff") format("woff");
  font-style: italic;
}
@font-face {
  font-family: "Reckless-RegularItalic";
  src: url("modules/asset/Reckless-RegularItalic.woff2") format("woff2");
  font-style: italic;
}

@font-face {
  font-family: "Reckless-Bold";
  src: url("modules/asset/Reckless-Bold.woff") format("woff");
  font-style: bold;
}
@font-face {
  font-family: "Reckless-Bold";
  src: url("modules/asset/Reckless-Bold.woff2") format("woff2");
  font-style: bold;
}
