// @import url('~normalize.css');
// NOTE: We're using a `.bp-` namespace to indicate these are the boilerplate
// styles.
//@import './scss/_settings';
//@import './scss/_type';
//@import './scss/_layout';
//@import './scss/_welcome';
//@import './scss/_widgets';

@import './css/mediakunst.css';
@import './css/stylesheet.css';

//because we use the z-order to fade images
.popover {
  z-index: 2000 !important;
}
.apos-area:first-child {
  margin-top: 0 !important
}

.apos-area:last-child {
  margin-bottom: 0 !important
}
